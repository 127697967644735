<template>
  <div class="PageInfo">
    <p class="w-full text-center text-system-gray uppercase text-sm py-3 border-t border-b border-primary-1-15">
      {{ t('showingXoutOf', { count: currentCount, total: totalCount }) }}
    </p>

    <button
      v-if="currentCount < totalCount"
      type="button"
      :disabled="isFetching"
      class="LoadMore flex items-center"
      @click="onNext"
    >
      <InfiniteScrollObserver @intersect="onNext" />
      <span class="underline uppercase mt-8">{{ t('loadMore') }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n({
  useScope: 'local',
});

const props = defineProps({
  currentCount: {
    type: Number,
    required: true,
  },
  totalCount: {
    type: Number,
    required: true,
  },
  isFetching: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['next']);

function onNext() {
  if (props.currentCount < props.totalCount) {
    emit('next');
  }
}
</script>

<style lang="postcss" scoped>
.PageInfo {
  @apply w-full flex flex-col items-center;

  .LoadMore {
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "showingXoutOf": "Showing {count} out of {total}",
    "loadMore": "Load More"
  },
  "ar": {
    "showingXoutOf": "{count} من {total}",
    "loadMore": "تحميل المزيد"
  }
}
</i18n>
